import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const TITLE_SUFFIX: string = "Odyssey Skin & Health"

const routes = [
    {
        path: "",
        component: () => import("./../../views/home/HomeController"),
        meta: {
            title: TITLE_SUFFIX
        }
    },
    {
        path: "/contact",
        component: () => import("./../../views/contact/ContactController"),
        meta: {
            title: `Contact - ${TITLE_SUFFIX}`
        }
    },
    {
        path: '/request-consultation',
        component: () => import("../../views/requestConsultation/RequestConsultationController"),
        meta: {
            title:  `Request Consultation - ${TITLE_SUFFIX}`
        }
    },
    {
        path: "*",
        redirect: "/"
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() { return { x: 0, y: 0 } },
});

router.afterEach((to, from) => {
    if (!to || !to.meta) {
        return;
    }

    document.title = to.meta.title;
});

export default router
