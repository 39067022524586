import Vue from 'vue'
import router from './main/router'

import * as firebase from "firebase/app";
import "firebase/analytics";

import "./main/less/main.less";
/// <reference path="./main/types/index.d.ts" />

Vue.config.productionTip = false

new Vue({
  router,
  template: "<router-view />"
}).$mount("#app");

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC-rsQmR09Rfiz8Q5f3Cz_5mvOjoj2zvZ4",
  authDomain: "odyssey-skin-and-health.firebaseapp.com",
  databaseURL: "https://odyssey-skin-and-health.firebaseio.com",
  projectId: "odyssey-skin-and-health",
  storageBucket: "odyssey-skin-and-health.appspot.com",
  messagingSenderId: "909269161676",
  appId: "1:909269161676:web:b3c7bbf18d6e41b2099469",
  measurementId: "G-YF8FB05QLS"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

